<template>
<v-dialog persistent :width="$vuetify.breakpoint.mobile?'100%': '90%'" v-model="dialog" :scrollable="false" >
  <div class="tw-flex tw-w-full tw-flex-col scroll">
    <div v-if="skeletonLoading" style="background: #FFFFFF; height: 100%">
      <v-skeleton-loader
                         class="mx-auto"
                         max-width="100%"
                         max-height="100%"
                         type="list-item-three-line, card-avatar, article, actions"
      ></v-skeleton-loader>
    </div>
    <div v-else class="tw-w-full tw-flex-col" style="background: #FFFFFF">
      <div class="tw-flex tw-w-full tw-justify-between tw-items-center lg:tw-px-14 tw-px-5 tw-py-3">
        <h6 class="nav-text">{{cvData.job_name}}</h6>
        <vue-feather class="tw-cursor-pointer" type="x" style="height: 14px;width: 14px; color:  #475661" @click="$emit('close')" />
      </div>
      <h6 class="candidate lg:tw-px-14 tw-px-5 tw-pb-3">{{cvData.name}}</h6>
      <v-divider />
      <div class="tw-flex  tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="3" lg="3" class="tw-flex lg:tw-flex-col tw-flex-row ">
<!--          <div class="lg:tw-flex tw-w-full tw-flex-col lg:tw-px-10 tw-px-5 tw-py-3" v-if="$vuetify.breakpoint.lgAndUp">-->
<!--            <h6 class="overall ">Overall Performance</h6>-->
<!--            <div class="tw-flex tw-w-full tw-flex-row tw-pb-3">-->
<!--              <div class="tw-w-1/2  tw-pt-5">-->
<!--                <h6 class="percent">{{cvData.evaluation === 'None Yet' ? 'None Yet' : cvData.evaluation+'%'}}</h6>-->
<!--                <h6 class="nav-text">Evaluation</h6>-->
<!--              </div>-->
<!--              <div class="tw-w-1/2 tw-pt-5">-->
<!--                <h6 class="percent">{{cvData.psychometric === 'None Yet' ? 'None Yet' : cvData.psychometric+'%'}}</h6>-->
<!--                <h6 class="nav-text">Psychometric</h6>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="tw-flex tw-w-full lg:tw-px-10 tw-flex-row lg:tw-flex-col tw-flex-wrap">
            <v-btn text class="nav-text lg:tw--ml-4" style="width: max-content" @click="viewSummary">
              <vue-feather class="tw-mr-3" style="height: 24px; width: 24px; color:#475661;" type="user" /> View Summary
            </v-btn>
            <v-btn text class="nav-text lg:tw-mt-3 lg:tw--ml-4" style="width: max-content" @click="download(cvData.name,cvData,cvData.cv_url)">
              <vue-feather class="tw-mr-3" style="height: 24px;width: 24px; color:#475661;" type="download" /> Download CV
            </v-btn>
          </div>
        </v-col>
        <v-divider class="tw-hidden lg:tw-flex" vertical/>
        <v-divider/>
        <v-col sm="12" md="9" lg="9" >
          <div class="tw-flex tw-w-full lg:tw-p-5">
            <iframe style="border: 1px solid #D0DCE4;" class="pdf-scroll tw-w-full" name="candidate cv" :src="cvData.cv_url"  />
          </div>
        </v-col>
      </div>
      <v-divider v-if="!$vuetify.breakpoint.mobile"/>
      <v-app-bar bottom color="white" :fixed="$vuetify.breakpoint.smAndDown" >
        <div class="tw-flex tw-w-full lg:tw-px-5 tw-py-10 tw-justify-between">
          <v-btn text class="tw-flex nav-text tw-items-center" @click="prevCandidate">
            <vue-feather type="arrow-left" style="height: 24px;width: 24px; color:#475661;"/>
            Previous {{$vuetify.breakpoint.mobile ? '' : 'Candidate'}} </v-btn>
          <v-btn text class="tw-flex nav-text tw-items-center" @click="nextCandidate">
            Next {{$vuetify.breakpoint.mobile ? '' : 'Candidate'}}
            <vue-feather type="arrow-right" style="height: 24px;width: 24px; color:#475661;"/>
          </v-btn>
        </div>
      </v-app-bar>
    </div>
  </div>
</v-dialog>
</template>

<script>
import {getCandidateCv} from "@/services/api/APIService";
// import axios from "axios";
import {viewLesson} from "@/services/storage/aws";

export default {
  name: "ViewCandidateCVModal",
  props:{
    dialog : [Boolean],
    data: Object
  },
  data(){
    return{
      cvData: {},
      currentUser : null,
      skeletonLoading : false,
      downloadCount : 0
    }
  },
  // beforeCreate() {
  //   this.getCandidateCv()
  // },
  watch:{
    dialog : {
      handler: function (val) {
        if (val) {
          this.getCandidateCv()
        }
      },
      immediate: true,
      deep:true
    }
  },
  methods:{
    async download(filename,filename1, url) {
      //new download fix
      const unlockedFile = url
      const anchor = document.createElement('a')
      anchor.href = unlockedFile
      anchor.setAttribute('target', '_blank')
      anchor.click()

      //old download view
      // axios({
      //   url: url,
      //   method: 'GET',
      //   responseType: 'blob',
      // }).then((response) => {
      //   let fileURL = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
      //   let fileLink = document.createElement('a');
      //
      //   fileLink.href = fileURL;
      //   fileLink.setAttribute('download', filename||filename1+".pdf");
      //   document.body.appendChild(fileLink);
      //   fileLink.click();
      //   this.downloadCount+=1
      // });
    },
    async getCandidateCv(){
      this.skeletonLoading = true
      await getCandidateCv(this.data).then(res =>{
        viewLesson({url: res.data.cv_url}).then(result => {
          res.data.cv_url = result
        })
        this.cvData = res.data
        console.log(this.cvData, 'hi')
      })
          .catch(err =>{
            this.skeletonLoading = false
            this.$displaySnackbar({message:err.response.data.detail, success:false})
          })
          .finally(()=>
              setTimeout(()=> {
                if (this.cvData.cv_url) this.skeletonLoading=false
              },3000)
          )
    },
    viewSummary(){
      let userData = JSON.parse(sessionStorage.getItem('userData'))
      console.log(userData)
      if (this.$route.name === 'Evaluation'){
        this.$emit('close')
      }else this.$router.push({name:'Evaluation',params:{id:userData.id}, query:{jobId:this.data.jobId,email:this.data.email}})
    },
    prevCandidate(){
      let currentIndex = null

      if (this.currentUser){
        currentIndex =  this.cvData.next.findIndex((value => value === this.currentUser))
      }
      else {
        currentIndex = this.cvData.next.findIndex((value => value === this.data.email))
      }
      console.log(currentIndex,this.currentUser)
      let data = {}
      if (currentIndex -1 >= 0){
        this.skeletonLoading = true
        this.currentUser = this.cvData.next[currentIndex -1]
         data.email = this.currentUser
        data.jobId = this.data.jobId
        getCandidateCv(data).then(res =>{
          viewLesson({url: res.data.cv_url}).then(result => {
            res.data.cv_url = result
          })
          this.cvData = res.data
        }).catch(err =>{
          this.skeletonLoading = false
              this.$displaySnackbar({message:err.response.data.detail, success:false})
            })
            .finally(()=>
                setTimeout(()=> {
                  this.skeletonLoading=false
                },3000)
            )
      } else {
        this.$displaySnackbar({message:"No more candidates", success:false})
      }

    },
    nextCandidate(){
      let currentIndex = null
      if (this.currentUser){
        currentIndex =  this.cvData.next.findIndex((value => value === this.currentUser))
      }
      else {
        currentIndex = this.cvData.next.findIndex((value => value === this.data.email))
      }
      console.log(currentIndex,this.currentUser)
      let data = {}
      if (currentIndex + 1 < this.cvData.next.length){
        this.skeletonLoading = true
        this.currentUser = this.cvData.next[currentIndex +1]
        data.email = this.currentUser
        data.jobId = this.data.jobId
        getCandidateCv(data).then(res =>{
          viewLesson({url: res.data.cv_url}).then(result => {
            console.log({result})
            res.data.cv_url = result

          })
          this.cvData = res.data
        }).catch(err =>{
           this.skeletonLoading = false
              this.$displaySnackbar({message:err.response.data.detail, success:false})
            })
            .finally(()=>
                setTimeout(()=> {
                   this.skeletonLoading=false
                },3000)
            )
      }
      else {
        this.$displaySnackbar({message:"No more candidates", success:false})
      }
    }
  },

}
</script>

<style scoped lang="scss">
.wrapper {
  //max-width: 1400px;
  //height: 100vh
}
.percent{
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.nav-text{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #1E323F;
  text-transform: none;
}
.candidate{
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #142E70;
}
.overall{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4F4F4F;
}
::v-deep .v-dialog {
  @media screen  and (max-width: 1023px){
    margin: 0 !important;
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  @media screen  and (max-width: 1023px){
    max-height: 100% !important;
  }
}
.scroll {
  //overflow-y: scroll;
  overflow-x: hidden;
  max-height: 90vh;
  //height: 90vh;
  border-radius: 8px;
  //padding: 0 4px 0 4px;

  @media screen  and (max-width: 1023px){
    max-height: 100vh;
    height: 100vh;
  }
}
.scroll::-webkit-scrollbar {
  width: 3px !important;
  height: 2px !important;
}
.scroll::-webkit-scrollbar-track {
  box-shadow: white;
  border-radius: 5px;
  height: 2px !important;
}
.scroll::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 5px;

}

.pdf-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 60vh;
  height: 60vh;
  border-radius: 8px;
  //padding: 0 4px 0 4px;

  @media screen  and (max-width: 800px){
    max-height: 77vh;
    height: 77vh;
  }
  @media screen  and (max-width: 500px){
    max-height: 68vh;
    height: 68vh;
  }
  @media screen and (width: 411px) and (height: 823px){
    max-height: 72vh;
    height: 72vh;
  }
  @media screen and (max-width: 400px){
    max-height: 60vh;
    height: 60vh;
  }
  @media screen and (max-width: 376px){
    max-height: 60vh;
    height: 60vh;
  }
  @media screen and (width: 375px) and (height: 812px){
    max-height: 67vh;
    height: 67vh;
  }
  @media screen and (max-width: 320px){
    max-height: 53vh;
    height: 53vh;
  }
}
.pdf-scroll::-webkit-scrollbar {
  width: 3px !important;
  height: 2px !important;
}
.pdf-scroll::-webkit-scrollbar-track {
  box-shadow: white;
  border-radius: 5px;
  height: 2px !important;
}
.pdf-scroll::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 5px;

}
</style>

<template>
<v-dialog v-model="dialog" persistent
          transition="dialog-top-transition" max-width="509" >
  <div class="tw-flex tw-w-full tw-items-center tw-justify-center">
    <v-card class=" tw-flex card tw-flex-col">
      <div class="tw-flex tw-flex-row tw-w-full tw-justify-between">
        <label class="card-title">Close Job Application</label>
        <vue-feather class="tw-cursor-pointer" type="x" @click="$emit('close')"/>
      </div>
      <label class="card-desc tw-mt-5">You are about to close applications for <b>{{jobTitle}}</b>,
        and candidates would no longer be able to apply for this job role once it is closed.
      </label>
      <div class="tw-flex tw-w-full lg:tw-justify-end tw-justify-between tw-mt-3 tw-flex-wrap tw-items-center">
        <v-btn text class="cancel-btn tw-mt-5 lg:tw-mr-5" @click="$emit('close')">Cancel</v-btn>
        <v-btn @click="closeJobPosting" :loading="loading" color="#FF2E2E"
               height="48" class="tw-mt-3 close-btn" width="auto">Close Application</v-btn>
      </div>
    </v-card>
  </div>
</v-dialog>
</template>

<script>
import {closeJobPosting} from "../../services/api/APIService";
export default {
  name: "CloseJobModal",
  props:{
    dialog : Boolean,
    jobTitle: [String],
    jobId : Number
  },
  data(){
    return{
      loading: false
    }
  },
  methods:{
    closeJobPosting(){
      this.loading = true
      const data={}
      data.jobId = this.jobId
      data.site_name = this.$siteName()
      closeJobPosting(data).then(()=>{
        this.loading = false
        this.$emit('close',true)
      }).catch(err =>{
        this.$displaySnackbar({
          message: err.response.data.message,
          success: false
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card{
  width: 590px;
  padding: 20px;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}
.card-title{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #2B1C1C;
}
.card-desc{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.cancel-btn{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #475661;
  text-transform: none;
}

.close-btn{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  text-transform: capitalize;
  border-radius: 8px;

}
.close-btn:hover{
  transform: scale(1.05);
  transition: 1s ease;
  background-color: #FF2E2E !important;
  color: #FFFFFF !important;
}
</style>
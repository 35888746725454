<template>
  <div style="margin-top: 40px" >
    <Loader v-if="loading" :loading="loading"/>
    <div v-else>
      <div v-if="jobDetail.created_by" class="tw-mt-20 md:tw-mt-12">
        <v-row class="tw-flex-col md:tw-flex-row">
          <v-col md="8" sm="8">
            <div>
              <BaseButton :to="'/dashboard/jobs'" class="tw-hidden md:tw-flex" button-text="Back" text color="#2B1C1C">
                <template #left-icon>
                  <v-icon color="#2B1C1C">mdi-chevron-left</v-icon>
                </template>
              </BaseButton>
              <v-row no-gutters class="tw-flex tw-w-full tw-items-center tw-flex-col lg:tw-flex-row md:tw-justify-between md:tw--mb-5">
                <v-col cols="12" md="8">
                  <div class="md:tw-ml-2">
                    <span class="head">{{jobDetail.title}}</span>
                    <p class="sub-head mt-1 text-capitalize">Created on {{convertDate(jobDetail.date_created)}}. by {{jobDetail.created_by || 'N/A'}}</p>
                  </div>
                </v-col>

                <v-col cols="12" md="4" class="tw-flex tw-w-full lg:tw-w-" v-if="!jobDetail.is_draft">
                  <CloseJobStatus :job-detail="jobDetail" @handleCloseJob="handleCloseJob"/>
                </v-col>
              </v-row>
              <v-row class="align-center ">
                <div class="tw-ml-5 tw-mr-5">
                  <ShareJobsModal :dialog="shareModal" @openShare="shareModal = true" outlined color="#475661" @close="closeShare" :job-title="jobDetail.title" :id="jobDetail.id" :logo="getCompanyProfile.company_logo || noImage"
                                  :job-type="jobDetail.type" :location="jobDetail.is_remote" :company="jobDetail.company_name"
                                  style="color: #475661"/>
                </div>
                <JobDetailsModal :date="convertDate(jobDetail.created_at)" :details="jobDetail"/>
              </v-row>
            </div>
          </v-col>
          <v-col md="1" class="tw-hidden lg:tw-block"></v-col>
          <v-col md="3" sm="4" class="tw-block">
            <div class="px-1 tw--mb-3 mt-2">
              <div v-if="!isStatusTrue && jobDetail.status !== 'closed'" class="criteriaCard tw-flex tw-justify-around py-4 text-center mb-2" @click="goToScreeningTest()">
                <span class="criteriaText text-wrap">Set Screening Criteria</span>
                <ArrowRightIcon style="color: #9CABB5"/>
              </div>
              <div class="criteriaCard tw-flex tw-justify-around py-4 text-center" @click="$router.push({name:'EvaluationCriteria', params:{id:$route.params.id}})">
                <span class="criteriaText text-wrap">Interview Evaluation Criteria</span>
                <ArrowRightIcon style="color: #9CABB5"/>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="tw-flex md:tw-justify-between tw-flex-col-reverse md:tw-flex-row">
          <v-col md="8" sm="8">
            <div>
              <div class="tw-flex md:tw-justify-between tw-flex-col-reverse md:tw-flex-row">
                <DashboardHorizontalTabSliders @tab:click="checkData" :tabs="tabs" id="tabs" class="tw-mt-3"/>
                <div class="tw-flex tw-justify-between mr-2">
                      <v-menu
                          top
                          transition="slide-x-transition"
                          :close-on-content-click="closeFilter"
                          v-model="menuData"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                              label
                              color="white"
                              class="filter_chip mt-3"
                              v-bind="attrs"
                              v-on="on"
                          >
                            <span class="name-filter ml-2 mt-1 tw-text-enumGrey2">Filter by</span>
                            <v-icon size="20" color="#000000" class="ml-1 tw-text-enumGrey2"> mdi-chevron-down</v-icon>
                          </v-chip>
                        </template>
                        <v-card
                            width="350"
                            class="px-6 pb-6 filter_card"
                            :loading="showLoader"
                        >
                          <div class="mt-4">
                            <span class="name-filter mt-1">Status</span>
                            <v-combobox :menu-props="{ maxHeight: '200' }" class="tw-w-90" v-model="searchCriteria.hiring_status" solo multiple chips item-color="whitesmoke" :items=newData>
                              <template v-slot:item="data">
                                <span color="#FFF2C7" class="text-capitalize">
                                  {{ statusText(data.item) }}
                                </span>
                              </template>
                              <template v-slot:selection="data">
                                <v-chip color="#FFF2C7" class="text-capitalize">
                                  <h3 class="mx-2">{{ data.item }}</h3>
                                  <v-icon
                                      small
                                      @click.prevent="deleteItem(searchCriteria.hiring_status, data.item)"
                                      class="ml-2"
                                  >
                                    mdi-close
                                  </v-icon>
                                </v-chip>
                              </template>

                            </v-combobox>
                            <div class="tw-flex">
                              <BaseButton button-text="Cancel All" outlined @click="cancelFilter"></BaseButton>
                              <BaseButton class="ml-2" button-text="Apply" @click="filterSearch"></BaseButton>
                            </div>
                          </div>
                        </v-card>
                      </v-menu>
                </div>
                <v-text-field class="tw-w-full lg:tw-w-1/2 md:tw-w-1/2" v-model="search"  hide-details label="Search" solo/>
              </div>
              <div>
                <div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-my-24" v-if="!isStatusTrue">
                  <EmptyStateComponent>
                    <template #empty-text>
                      <div class="empty  md:tw-w-400">
<!--                        <h3>Set Screening Test</h3>-->
                        <h5>Candidates would be filtered during a  1-week window after setting a screening criteria for best-fit.</h5>
                        <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
                          <BaseButton  @click="goToScreeningTest()" width="159px" height="40px" button-text="Go To Next Step"></BaseButton>

                        </div>
                      </div>

                    </template>
                  </EmptyStateComponent>
                </div>
                <div class="card-table" v-else>
                  <v-data-table
                      :headers="headers"
                      :mobile-breakpoint="0"
                      :search="search"
                      item-key="id"
                      height="40vh"
                      @click:row="gotoRoute"
                      :hide-default-footer="tableData.length<=0"
                      :items="tableData"
                      :no-data-text="not_found"
                      class="mt-4 mx-1 table"
                  >
                    <template #header.applicant>
                      <span class="status mt-3">CANDIDATES</span>
                    </template>
<!--                    <template #header.score>-->
<!--                      <span class="status mt-3">EVALUATION</span>-->
<!--                    </template>-->
                    <template #header.status>
                    <span class="status mt-3">STATUS</span>
                  </template>
                    <template v-slot:[`item.applicant`]="{ item }">
                      <div class="tw-flex">
                        <v-avatar color="#ECF4F7" class="card-avatar" size="40px">{{getName(item.applicant)}}</v-avatar>
                        <span class="name mt-2 ml-1 text-capitalize text-no-wrap">{{item.applicant}}</span>
                      </div>
                    </template>
<!--                    <template v-slot:[`item.score`]="{ item }">-->
<!--                      <span class="name ml-5 text-no-wrap">{{(item.score)}}</span>-->
<!--                    </template>-->
                    <template v-slot:[`item.status`]="{ item }">
                      <span class="status text-capitalize text-no-wrap">{{statusText(item.status)}}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-menu bottom offset-y left>
                        <template v-slot:activator="{ on, attrs }">
                          <td class="d-flex justify-end">
                            <v-btn v-bind="attrs" v-on="on" icon>
                              <v-icon color="black">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </td>
                        </template>
                        <v-list>
                          <v-list-item class="tw-cursor-pointer">
                            <span></span>
                            <v-list-item-title @click="goToInterview(item)">Invite For Interview</v-list-item-title>
                          </v-list-item>
                          <v-list-item class="tw-cursor-pointer">
                            <span></span>
                            <v-list-item-title @click="showCvModal(item)">View CV</v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <MoveToTalentPoolModal :item="item">
                              <v-list-item-title id="move_to_talent_pool">Add to Talent Pool</v-list-item-title>
                            </MoveToTalentPoolModal>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                </div>

              </div>
            </div>

          </v-col>
          <v-col md="1" class="tw-hidden lg:tw-block"></v-col>
          <v-col md="3" sm="4">
            <div class="px-1 mt-1">
                <JobInsightWebView :job-detail="jobDetail"/>
                <JobInsightMobileView :job-detail="jobDetail"/>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <close-job-modal :dialog="closeJob" @close="handleCloseJob" :job-title="jobDetail.title" :job-id="jobDetail.id" />
    <view-candidate-c-v-modal :dialog="cvModal" @close="showCvModal" :data="candidateData" />
  </div>

</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import { ArrowRightIcon} from 'vue-feather-icons'
import DashboardHorizontalTabSliders from "@/components/dashboard/reuseables/DashboardHorizontalTabSliders";
import {mapGetters} from "vuex";
import moment from "moment";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import getSiteName from "@/services/utils";
import MoveToTalentPoolModal from "@/components/talentPool/MoveToTalentPoolModal";
import CloseJobModal from "./CloseJobModal";
import EmptyStateComponent from "@/components/reusables/EmptyStateComponent";
import {getScreeningStatus} from "@/services/api/APIService";
import JobInsightWebView from "@/components/viewAdminJobs/JobInsightWebView";
import JobInsightMobileView from "@/components/viewAdminJobs/JobInsightMobileView";
import CloseJobStatus from "@/components/viewAdminJobs/CloseJobStatus";
import ViewCandidateCVModal from "./ViewCandidateCVModal";
import ShareJobsModal from "@/components/jobPosting/reuseables/ShareJobsModal";
import JobDetailsModal from "@/components/viewAdminJobs/JobDetailsModal";
import _ from 'lodash'

export default {
  name: "AllCandidatesPage",
  components: {
    JobDetailsModal,
    ShareJobsModal,
    ViewCandidateCVModal,
    CloseJobStatus,
    JobInsightMobileView,
    JobInsightWebView,
    EmptyStateComponent,
    CloseJobModal,
    MoveToTalentPoolModal,
    Loader, DashboardHorizontalTabSliders, BaseButton,
    ArrowRightIcon},
  data() {
    return {
      closeJob : false,
      loading: false,
      closeFilter: false,
      menuData: false,
      showLoader: false,
      noImage: 'https://images.unsplash.com/photo-1632169505206-9506f48761e6?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
      insightIcon: false,
      screeningIcon: false,
      shortlistingIcon: false,
      sortBy: '',
      search: '',
      shareModal: false,
      tableData: [],
      searchCriteria: {
        hiring_status: [],
      },
      jobDetails:{},
      tableNumber: [],
      newData: [],
      searchResults: [],
      statusItem: ['qualified', 'declined', 'shortlisted', 'accepted', 'withdrawn', 'interviewed', 'interview_scheduled', 'interviewing', 'offered_employment', 'interview_declined'],
      answer: 'Yes',
      not_found: 'Candidates that are shortlisted will be displayed here',
      tabs: [
        {
          name: 'All',
        },
        {
          name: 'Hired',
        },
        {
          name: 'Rejected',
        },
      ],
      headers: [
        {
          text: "",
          align: "start",
          value: "applicant"
        },
        // {text: "", value: "score"},
        {text: "", value: "status"},
        {text: "", value: "actions", align: "end"}
      ],
      screeningStatus: {},
      cvModal : false,
      candidateData:{}
    }
  },
  computed: {
    ...mapGetters('adminJobs', ['jobDetail', 'listCandidates']),
    ...mapGetters('editCompany', ['getCompanyProfile']),
    today(){
      return moment(Date.now()).format('YYYY-M-D')
    },
    isStatusTrue(){
      let allValue = Object.values(this.screeningStatus)
      console.log(allValue,'all')
      return allValue.some(e => e===true)
    },
  },
  mounted() {
    this.getScreeningStatus()
    // this.isStatusTrue()
  },
  methods: {
    closeShare(){
      this.shareModal = false
    },
    statusText(name) {
      return name.replace(/_/g,' ')
    },
    showCvModal(item){
      console.log(item)
      this.cvModal = !this.cvModal
      if (item){
        this.candidateData.email = item.email
        this.candidateData.jobId = item.job_id
      }
      console.log(item)
    },
    jobPostingDeadline(deadline){
      return moment(deadline).format('YYYY-M-D')
    },
   async handleCloseJob(refresh){
     if (this.closeJob && refresh){
       this.closeJob = !this.closeJob
       this.loading = true
       await this.getJobDetails();
       await this.getCandidates()
       this.loading = false
     }
     else {
       this.closeJob = !this.closeJob
     }

    },
    getName(item) {
      if (!item) return
      const fullName = item.split(' ');
      const initials = fullName && fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    },
    gotoRoute(item) {
      this.$router.push({name:"CandidateProfile",query:{applicant_id: item.id, jobId:this.$route.params.id,email:item.email}})
    },
    convertDate(val) {
      return moment(val).format('ll')
    },
    processedDate(createdDate) {
      if (!createdDate) return;
      let day;
      let date, now
      day = createdDate.split("T")[0]
      let d1 = date = new Date(day);
      let d2 = now = new Date();
      if (d2.getTime() < d1.getTime()) {
        d1 = now;
        d2 = date;
      }
      let yd = d1.getYear();
      let yn = d2.getYear();
      let years = yn - yd;
      let md = d1.getMonth();
      let mn = d2.getMonth();
      let months = mn - md;
      if (months < 0) {
        years--;
        months = 12 - md + mn;
      }
      let dd = d1.getDate();
      let dn = d2.getDate();
      let days = dn - dd;
      if (days < 0) {
        months--;
        // figure out how many days there are in the last month
        d2.setMonth(mn, 0);
        days = d2.getDate() - dd + dn;
      }
      let weeks = Math.floor(days / 7);
      days = days % 7;
      if (years > 0) return 'Closes in '+ years + 'year' +  (years > 1 ? "s" : "")
      if (months > 0) return 'Closes in '+ months + ' month' +  (months > 1 ? "s" : "")
      if (weeks > 0) return 'Closes in ' + weeks + ' week' +  (weeks > 1 ? "s" : "")
      if(days > 0) return 'Closes in '+ days + ' day' +  (days > 1 ? "s" : "")
      return "Closed";

    },

    checkData(item) {
      if(item === 'All') {
        this.tableData = this.listCandidates
      }
      else if (item === 'Hired') {
        this.tableData = this.listCandidates.filter(item => item.status === 'Hired')
      }
      else if (item === 'Rejected') {
        this.tableData = this.listCandidates.filter(item => item.status === 'Rejected')
      }
      else this.tableData = this.listCandidates
    },
    async getJobDetails() {
      await this.$store.dispatch('adminJobs/getAllJobDetails', this.$route.params.id)
    },
    async getCandidates() {
      await this.$store.dispatch('adminJobs/getAllListCandidates', {jobId:this.$route.params.id,site_name:getSiteName()})
      this.populateData(this.listCandidates,'api')

    },
    goToInterview(item){
      if(item.status === 'Qualified' || item.status === 'Interviewed'|| item.status === 'shortlisted') {
        this.$router.push(
            {
              name: 'InterviewScheduleView',
              params: {step: '1'},
              query: {
                jobId: this.$route.params.id,
                id: item.id,
                email: item.email,
                name: item.applicant

              }
            })
      }
      else {
        this.$displaySnackbar({
          message:'Candidate cannot be invited for interview with current application status',
          success:false
        })
      }
    },
    goToScreeningTest(){
      this.$router.push({name:'ScreeningOptionView',query:{jobId:this.$route.params.id,title:this.jobDetail.title}})
    },
    getScreeningStatus() {
      getScreeningStatus(this.$route.params.id)
          .then(({data}) => {
            this.screeningStatus= data
          }).catch(error => {
        console.log(error)
      })
    },
    deleteItem(data, item) {
      data.splice(data.indexOf(item), 1)
      data = [...data]
    },
    cancelFilter() {
      this.menuData = false;
      this.populateData(this.listCandidates,'api')
    },
    filterSearch() {
      const finalResultsSet = new Set()
      const results = []
      let finalResults = []
      if(this.tableData.length > this.searchResults.length) {
        this.populateData(this.tableData,'api')
      }
      const criteriaKeys = Object.values(this.searchCriteria.hiring_status)
      criteriaKeys.forEach(stat => {
          results.push(this.searchResults.filter(c => c.status === stat))
      })
      // results.map((result)=>{
      //   finalResultsSet.add(result)
      // })
      finalResultsSet.add(...results)
      finalResults = Array.from(finalResultsSet)
      console.log({finalResults})
      if (finalResults.length === 0){
        this.populateData(this.tableData,'api')
      }
      else {
        this.populateData(finalResults,"filter")
      }
    },
    populateData(data, key) {
      let searchResult = []
      if (key === 'api'){
        this.searchResults = data
        this.getTalentPoolAndStatus(data)
      }
      else {
        data.map((arr)=>{
          searchResult.push(...arr)
        })
        searchResult= _.uniq(searchResult)
        this.menuData = false;
        this.getTalentPoolAndStatus(searchResult)
      }

    },
    getTalentPoolAndStatus(pools){
      const talentStatus = new Set();
      console.log(pools)
      this.tableData = pools
      pools.map((pool)=>{
        talentStatus.add(pool.status)
      })
      this.newData = Array.from(talentStatus)

    },
    async getProfile(){
      await this.$store.dispatch('editCompany/getCompanyProfile', this.$siteName())
    },

  },
  async created() {
    this.loading = true
    await this.getProfile()
    await this.getJobDetails();
    await this.getCandidates()
    this.loading = false
    this.checkData('All')
  },


}
</script>

<style scoped>
.v-btn{
  font-weight: 400 !important;
}
.head{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #142E70;
}
.sub-head{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: #475661;
}
.timeCard{
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 10px;
}
.date {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
.closeJob{
  background: #FFF7F7;
  border: 1px solid #FEBDBD;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: DM Sans,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #FF2E2E;
  padding: 10px 16px;

}
.card-table {
  border-top: 0 !important;
}
.name {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 5px;

  color: #1E323F;
}
.status {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  color: #1E323F;
}
.criteriaCard {
  background: #FFFFFF !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  cursor: pointer;
}
.criteriaText h3{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4F4F4F;
}
.criteriaText {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.num {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #4F4F4F;
}
.chips {
  background: #F7FCFF !important;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 80px;
}
.screen {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 15vh;
  padding: 0 4px 0 4px;
}
.screen::-webkit-scrollbar {
  width: 3px !important;
  height: 2px !important;
}
.screen::-webkit-scrollbar-track {
  box-shadow: white;
  border-radius: 5px;
  height: 2px !important;
}
.screen::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 5px;

}
.table >>> ::-webkit-scrollbar {
  width: 3px !important;

}
.table {
  overflow-x: hidden !important;
  cursor: pointer;

}
.table >>> ::-webkit-scrollbar-track {
  box-shadow: white;
  border-radius: 5px;
}

.table >>> ::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 5px;
}
.table >>> .mdi-checkbox-blank-outline::before {
  margin-bottom: 14px !important;
}

.card-avatar {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #058EF2;
}
.empty h3{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #1E323F;
  margin-top: 15px;
}
.empty h5{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #475661;
  margin-top: 7px;
  margin-bottom:15px
}

.name-filter {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
}
</style>

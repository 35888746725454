<template>
<div>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#008EEF"/>
    <g clip-path="url(#clip0)">
      <path d="M13.3157 7.51172L8.75612 12.0713L6.68359 9.99875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="9.94813" height="9.94813" fill="white" transform="translate(5.02539 5.02539)"/>
      </clipPath>
    </defs>
  </svg>
</div>
</template>

<script>
export default {
  name: "SelectedBlueIcon"
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-dialog v-model="dialog2" max-width=" 500">
      <template v-slot:activator="{ on, attrs }">
        <div
            v-bind="attrs"
            id="move_to_talent_pool"
            v-on="on"
        >
          <slot></slot>
        </div>
      </template>

      <v-card class="pa-5" width="500">
        <div>
          <div class="d-flex  justify-space-between">
            <h3 class="titleModal">Select Talent Pool</h3>
            <v-btn class="transparent-btn" icon text @click="dialog2 = false" data-testid="cancel-modal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <div class="my-6">
            <v-text-field class="" v-model="search" hide-details label="Search" solo/>
          </div>
          <div class="scroll">

            <Loader v-if="pageLoading"/>
            <div v-else>

              <div v-if="returnedFilteredSearch.length > 0 && search !== '' ">
                <div v-for="(talentPool, index) in filteredResult" :key="index">
                  <v-card :color="pool[talentPool.name]? '#EEF8FF': ''" @click="getTalentPool(talentPool)" flat
                          class="pa-2 d-flex align-center justify-space-between">
                    <div>
                      <h3 :class="pool[talentPool.name]? 'active': ''" class="poolName text-capitalize">
                        {{ talentPool.name }}</h3>
                      <h4 class="candidate">{{
                          talentPool.candidates.length
                        }}{{ talentPool.candidates.length > 1 ? ' candidates' : ' candidate' }}</h4>
                    </div>
                    <div v-if="pool[talentPool.name]">
                      <SelectedBlueIcon/>
                    </div>
                  </v-card>
                </div>
              </div>

              <div v-if="returnedFilteredSearch.length === 0 && search !== '' ">
                <EmptyStateComponent>
                  <h4>No result matches your search</h4>
                </EmptyStateComponent>
              </div>

              <div v-if="all_talentPool.length > 0 && search === ''">
                <div v-for="(talentPool, index) in talentPools" :key="index">
                  <v-card :color="pool[talentPool.name]? '#EEF8FF': ''" @click="getTalentPool(talentPool)" flat
                          class="pa-2 d-flex align-center justify-space-between">
                    <div>
                      <h3 :class="pool[talentPool.name]? 'active': ''" class="poolName text-capitalize">
                        {{ talentPool.name }}</h3>
                      <h4 class="candidate">{{
                          talentPool.candidates.length
                        }}{{ talentPool.candidates.length > 1 ? ' candidates' : ' candidate' }}</h4>
                    </div>
                    <div v-if="pool[talentPool.name]">
                      <SelectedBlueIcon/>
                    </div>
                  </v-card>
                </div>
              </div>
            </div>
          </div>

          <div class="tw-flex actions align-center justify-space-between mt-6">
            <CreateTalentPoolModal :move-data="{item:item,pool_id: this.talentPoolId}">
              <template #button>
                <div @click="$emit('create'), (dialog2 = false)" class="d-flex pa-1 align-center custom-class">
                  <PlusIcon size="20" class="mr-1"/>
                  <h4>Create</h4>
                </div>
              </template>
            </CreateTalentPoolModal>
            <div class="tw-flex actions align-center tw-justify-start sm:tw-justify-end mt-2">
              <base-button type="button" @click="closeDialog()" :depressed="true" id="cancel" class="mr-4"
                           :outlined="true" button-text="Cancel"/>
              <base-button :disabled="returnedFilteredSearch.length === 0 && search !== ''?true:false " type="submit"
                           @click="moveToTalentPool()" :loading="loading" :depressed="true" id="move" :width="'90px'"
                           button-text="Move"/>
            </div>
          </div>
        </div>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import CreateTalentPoolModal from "@/components/talentPool/CreateTalentPoolModal";
import {mapGetters} from "vuex";
import getSiteName from "@/services/utils";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import SelectedBlueIcon from "@/components/talentPool/SelectedBlueIcon";
import EmptyStateComponent from "@/components/UIComponents/EmptyStateComponent";
import {addCandidateToATalentPool} from "@/services/api/APIService";
import {PlusIcon} from "vue-feather-icons"

export default {
  name: "MoveToTalentPoolModal",
  components: {EmptyStateComponent, SelectedBlueIcon, Loader, CreateTalentPoolModal, PlusIcon, BaseButton},
  data() {
    return {
      loading: false,
      search: "",
      dialog2: false,
      talentPoolId: "",
      value: false,
      pool: {},
      pageLoading: false,
      newTalentPool: [],
      filteredResult: []
    }
  },
  props: {
    item: Object,
  },
  watch: {
    search: function (newValue, oldValue) {
      console.log(oldValue)
      if (this.$route.params.talentPoolId && this.$route.name === "AllCandidateInATalentPool") {
        let allTalentPool = [...this.talentPools]
        this.filteredResult = [...allTalentPool.filter(pool => (pool.name.toLowerCase().indexOf(newValue.toLowerCase()) !== -1) || (pool.created_by.toLowerCase().indexOf(newValue.toLowerCase()) !== -1))]
        console.log(this.filteredResult)
      } else {
        let allTalentPool = [...this.all_talentPool]
        this.filteredResult = [...allTalentPool.filter(pool => (pool.name.toLowerCase().indexOf(newValue.toLowerCase()) !== -1) || (pool.created_by.toLowerCase().indexOf(newValue.toLowerCase()) !== -1))]
        console.log(this.filteredResult)
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog2 = false;
    },
    getTalentPool(talentPool) {
      this.talentPoolId = talentPool.id
      this.pool = {
        [talentPool.name]: true
      }
      console.log(this.pool)
    },
    moveToTalentPool() {
      this.loading = true
      let siteName = getSiteName()

      if (this.$route.params.talentPoolId) {
        let data = {
          email: this.item.candidate_email,
          site_name: siteName,
          pool_id: this.talentPoolId,
          current_pool_id: this.$route.params.talentPoolId
        }
        console.log(data)
        this.$store.dispatch("talentPool/moveCandidateToATalentPool", data)
            .then(res => {
              this.loading = false
              console.log(res)
              this.$displaySnackbar({message: "Candidate moved successfully", success: true})
              this.dialog = false
            })
            .catch(err => {
              this.loading = false
              console.log(err)
              console.log(err.response.status)
              if (err.response.status === 409) {
                this.$displaySnackbar({message: err.response.data.detail, success: false})
                // this.$displaySnackbar({message: 'Only accepts Images', success: false})
              } else {
                this.$displaySnackbar({message: "Something went wrong", success: false})
              }
            })
      } else if (this.item.talent_pool === "Unlisted") {
        let data = {
          email: this.item.email,
          site_name: siteName,
          pool_id: this.talentPoolId
        }
        console.log(data)
        addCandidateToATalentPool(data)
            .then(res => {
              console.log(res)
              this.loading = false
              this.$displaySnackbar({message: "Candidate moved successfully", success: true})
              this.dialog = false
            })
            .catch(err => {
              console.log(err)
              this.$displaySnackbar({message: err.response.data.detail, success: false})
              this.loading = false
            })
      } else {
        let data = {
          email: this.item.email,
          site_name: siteName,
          pool_id: this.talentPoolId
        }
        addCandidateToATalentPool(data)
            .then(res => {
              console.log(res)
              this.loading = false
              this.$displaySnackbar({message: "Candidate moved successfully", success: true})
              this.dialog = false
            })
            .catch(err => {
              console.log(err)
              this.$displaySnackbar({message: err.response.data.detail, success: false})
              this.loading = false
            })
      }

    }
  },

  computed: {
    ...mapGetters("talentPool", ["all_talentPool"]),
    returnedFilteredSearch() {
      return this.filteredResult
    },
    talentPools() {
      if (this.$route.params.talentPoolId && this.$route.name === "AllCandidateInATalentPool") {
        let id = parseInt(this.$route.params.talentPoolId)
        let newArray = this.all_talentPool.filter(item => item.id !== id)
        console.log(newArray)
        return newArray
      } else {
        return this.all_talentPool
      }
    }
  },
  created() {
    this.pageLoading = true
    let siteName = getSiteName()
    this.$store
        .dispatch("talentPool/getAllTalentPool", siteName)
        .then(res => {
          console.log(res);
          this.pageLoading = false
        })
        .catch(err => {

          console.log(err);
          this.pageLoading = false
        });

  },
}
</script>

<style scoped lang="scss">
.transparent-btn {
  background-color: transparent !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}

.titleModal {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}

.poolName {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.candidate {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #9CABB5;
}

.scroll {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 20px !important;
  border-radius: 6px;
}

.theme--light.v-card {
  color: #EEF8FF;
}

.active {
  color: #008EEF;
}

.custom-class {
  color: #008EEF;
}

.transparent-btn {
  background-color: transparent !important;
  padding: 0 0 !important;
}

.actions {

  @media (max-width: 380px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.actions #cancel {
  @media (max-width: 380px) {
    margin-left: 16px;
  }
  @media (max-width: 280px) {
    margin-bottom: 8px;
  }
}

.actions #move {
  @media (max-width: 380px) {
    //margin-top: 10px;
  }
}
</style>
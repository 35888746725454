<template>
  <div>
    <v-dialog
        v-model="dialog"
        width="370"
        >
    <template v-slot:activator="{ on, attrs }">
      <div class="more"
           v-bind="attrs"
           v-on="on"
      >
        <h1>...More</h1>

      </div>
    </template>
      <v-card class="wrapper " >
        <div class="tw-flex tw-justify-between tw-mt-3 tw-px-3 tw-mb-2 tw-items-center">
          <h1 class="title ">
            {{details.title}}
          </h1>
          <h1 class="tw-cursor-pointer font-weight-bold" @click="dialog = false">X</h1>
        </div>
        <h3 class="created tw-px-3">Created on {{date}}. by {{details.created_by || 'N/A'}} </h3>
        <div class="details tw-p-3">
              <div class="tw-flex tw-mt-4  md:tw-pr-16 tw-justify-between summary">
                <div>
                  <h3>Type</h3>
                  <h2>{{details.type}}</h2>
                  <h3>Location</h3>
                  <h2>{{details.location}}</h2>
                </div>
                <div>
                  <h3>Level</h3>
                  <h2>{{details.experience_level}}</h2>
                  <h3>Location type</h3>
                  <h2>{{details.is_remote?'Remote':'On site'}}</h2>
                </div>
              </div>
          <v-divider class="tw-my-6"></v-divider>
          <div class="actions">

            <div class="tw-flex tw-justify-between tw-cursor-pointer" @click="openUrl">
              <div>
                <h4>View Job as Applicant</h4>
                <p>{{url}}</p>
              </div>
                <vue-feather style="color:#9CABB5" type="arrow-right"/>

            </div>
<!--            <div class="tw-flex tw-justify-between">-->
<!--              <div>-->
<!--                <h4>Conclude job</h4>-->
<!--                <p>Complete hiring cycle</p>-->
<!--              </div>-->
<!--              <vue-feather style="color:#9CABB5" type="arrow-right"/>-->

<!--            </div>-->

          </div>


          </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
name: "JobDetailsModal",
  data(){
    return{
      dialog: false,
      url:''
    }
  },
  props:{

    details:[Object],
    date:[String]
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      this.url = `http://localhost:8080/search/details?jobId=${this.$route.params.id}`
    } else {
      this.url = `${process.env.VUE_APP_PROD_TALENT_FRONT_URL}/search/details?jobId=${this.$route.params.id}`
    }
  },
  methods: {
    openUrl() {
      let link = document.createElement("a");
      link.target = "_blank"
      link.href = this.url
      link.click();
    }
  }
}
</script>

<style scoped>
.wrapper{
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
  padding: 15px;
}
.title{
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #2B1C1C;

}
.more{
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 20px;
  display: flex;
  justify-content:center;

}
.more h1{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.details h3{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #9CABB5;
}
.details h2{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 15px;
  color: #475661;
}
.actions h4{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.actions p{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #9CABB5;
}
.created{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;

}
</style>

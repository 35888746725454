<template>

  <div class="horizontal-tab-slider-wrapper d-flex justify-space-between tabbs"
       style="width: 100%;">
    <div>
      <v-tabs :color="'#008eef'"
              :slider-color="'#008eef'" active-class="tw-font-normal"
              slider-size="3">
        <v-tab v-for="(tab, i) in tabs" :key="i" :to="tab.to" @click="$emit('tab:click', tab.name)" >
          <div class="tw-px-3 sm:tw-px-5">
            <p class=" tw-text-enumGrey2 horizontal-tab-slider-tab-item">{{ tab.name }}</p>
          </div>

        </v-tab>
      </v-tabs>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
name: "DashboardHorizontalTabSliders",
  props: {
    tabs: Array
  }
}
</script>

<style lang="scss" scoped>
//.horizontal-tab-slider-active {
//  font-weight: bold;
//}
//.horizontal-tab-slider-tab-item {
//  //color: #4f4f4f;
//}
p{
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: capitalize;
  //transition: all .1s ease-out;

}
.v-application p {
  margin-bottom: 1px !important;
}
.v-tab{
  min-width: 0;
  padding: 0;
}
.tabbs
::v-deep .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
> .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
.v-slide-group__prev {
  display: none !important;
}

</style>
